@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #3B82F6;
  --color-primary-dark: #2563EB;
  --color-text: #111827;
  --color-text-light: #374151;
  --color-background: #FFFFFF;
  --color-background-alt: #F3F4F6;
}

.dark {
  --color-primary: #60A5FA;
  --color-primary-dark: #3B82F6;
  --color-text: #F3F4F6;
  --color-text-light: #D1D5DB;
  --color-background: #111827;
  --color-background-alt: #1F2937;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom utility classes */
@layer utilities {
  .bg-primary {
    @apply bg-blue-600 dark:bg-blue-500;
  }

  .text-primary {
    @apply text-blue-600 dark:text-blue-400;
  }

  .border-primary {
    @apply border-blue-600 dark:border-blue-500;
  }

  .hover-primary {
    @apply hover:text-blue-700 dark:hover:text-blue-300;
  }

  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
  .animation-delay-100 {
    animation-delay: 100ms;
  }
  .animation-delay-200 {
    animation-delay: 200ms;
  }
  .animation-delay-300 {
    animation-delay: 300ms;
  }
  .animation-delay-400 {
    animation-delay: 400ms;
  }
  .animation-delay-500 {
    animation-delay: 500ms;
  }
  .animation-delay-600 {
    animation-delay: 600ms;
  }
  .animation-delay-700 {
    animation-delay: 700ms;
  }
  .animation-delay-800 {
    animation-delay: 800ms;
  }
  .animation-delay-900 {
    animation-delay: 900ms;
  }
  .animation-delay-1000 {
    animation-delay: 1000ms;
  }
}

/* Animation keyframes */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

@keyframes rotate-slow {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-slow-reverse {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Animation classes */
.animate-fadeInUp {
  animation: fadeInUp 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-fadeInLeft {
  animation: fadeInLeft 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-fadeInRight {
  animation: fadeInRight 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out forwards;
}

.animate-blob {
  animation: blob 7s infinite;
}

.animate-rotate-slow {
  animation: rotate-slow 20s linear infinite;
}

.animate-rotate-slow-reverse {
  animation: rotate-slow-reverse 20s linear infinite;
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Animation delays */
.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

.animation-delay-800 {
  animation-delay: 800ms;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

.delay-100 {
  animation-delay: 100ms;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}

/* Scroll Animations */
.scroll-section {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-section.animate-on-scroll {
  opacity: 1;
  transform: translateY(0);
}

.scroll-section-left {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-section-right {
  opacity: 0;
  transform: translateX(50px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-section-left.animate-on-scroll,
.scroll-section-right.animate-on-scroll {
  opacity: 1;
  transform: translateX(0);
}

.scroll-fade {
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-fade.animate-on-scroll {
  opacity: 1;
}

/* Stagger children animations */
.stagger-children > * {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.stagger-children.animate-on-scroll > *:nth-child(1) { transition-delay: 0ms; }
.stagger-children.animate-on-scroll > *:nth-child(2) { transition-delay: 100ms; }
.stagger-children.animate-on-scroll > *:nth-child(3) { transition-delay: 200ms; }
.stagger-children.animate-on-scroll > *:nth-child(4) { transition-delay: 300ms; }

.stagger-children.animate-on-scroll > * {
  opacity: 1;
  transform: translateY(0);
}

/* Glass effect and card styles */
.glass-effect {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.floating-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1),
              0 0 40px rgba(0, 0, 0, 0.1);
}

/* Form styles */
.input-style {
  @apply w-full bg-white/5 border border-white/10 text-white rounded-xl px-4 py-3 
         focus:outline-none focus:ring-2 focus:ring-blue-500/50 focus:border-blue-500/50
         placeholder-gray-400 backdrop-blur-sm transition-all duration-300;
}

.btn-primary {
  @apply py-3 px-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold 
         rounded-xl transition-all duration-300 hover:opacity-90 focus:outline-none 
         focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50;
}

/* Dark text colors */
.text-dark-text-secondary {
  @apply text-gray-400;
}
